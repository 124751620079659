import React, { Component } from 'react';
import LayoutWrapper from 'src/layouts/LayoutWrapper'
import './textPage.scss'

import { processJson } from 'utils'

class TextPage extends Component {

  render() {

    const {
      data
    } = this.props

    const thisPageData = data.contentfulTextPage

    return (
      <LayoutWrapper>
        <div className="text-page-container">
          <h1>
            {thisPageData.title}
          </h1>
          <p>
          </p>
          <div className="content">
            { processJson(thisPageData.content) }
          </div>
        </div>
      </LayoutWrapper>
    )
  }
}

export default TextPage;

export const pageQuery = graphql`
  query textPageQuery($id: String!) {
    contentfulTextPage(id: { eq: $id }) {
      title
      content {
        json
      }
    }
  }
`;

